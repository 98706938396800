.block-approach-wheel {
  padding-bottom: 2.5rem;
  overflow: hidden;
  background-color: #1b232c;
  justify-content: center;
  display: grid;
}

/* @media (min-width: 992px) {
	.block-approach-wheel { */
/* padding-bottom: 6.25rem */
/* }
} */

.block-approach-wheel.block-approach-wheel--half {
  padding-bottom: 0;
}

.block-approach-wheel.block-approach-wheel--half .circle-carousel {
  margin-top: 6.25rem;
}

@media (min-width: 992px) {
  .block-approach-wheel.block-approach-wheel--half .circle-carousel {
    margin-top: 12.5rem;
  }
}

.block-approach-wheel.block-approach-wheel--half .slides h2,
.block-approach-wheel.block-approach-wheel--half .slides .slide-copy {
  margin-top: -5.3125rem;
}

.block-approach-wheel > .container {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 2.5rem;
}

@media (min-width: 992px) {
  .block-approach-wheel > .container {
    padding-top: 6.25rem;
  }
}

.block-approach-wheel #js-prev,
.block-approach-wheel #js-next {
  margin-top: 2.5rem;
  cursor: pointer;
}

@media (min-width: 992px) {
  .block-approach-wheel #js-prev,
  .block-approach-wheel #js-next {
    margin-top: 5rem;
  }
}

@media (max-width: 992px) {
  .block-approach-wheel #js-prev svg,
  .block-approach-wheel #js-next svg {
    width: 2.25rem;
    height: 2.25rem;
  }
}

@-webkit-keyframes pulse-secondary {
  0% {
    stroke: #fff;
  }

  50% {
    stroke: #6be5b2;
  }

  100% {
    stroke: #fff;
  }
}

@keyframes pulse-secondary {
  0% {
    stroke: #fff;
  }

  50% {
    stroke: #6be5b2;
  }

  100% {
    stroke: #fff;
  }
}

.block-approach-wheel.current-group-1 .arrow svg {
  -webkit-animation: pulse-secondary 2s infinite;
  animation: pulse-secondary 2s infinite;
}

@-webkit-keyframes pulse-purple {
  0% {
    stroke: #fff;
  }

  50% {
    stroke: #bfa4f9;
  }

  100% {
    stroke: #fff;
  }
}

@keyframes pulse-purple {
  0% {
    stroke: #fff;
  }

  50% {
    stroke: #bfa4f9;
  }

  100% {
    stroke: #fff;
  }
}

.block-approach-wheel.current-group-2 .arrow svg {
  -webkit-animation: pulse-purple 2s infinite;
  animation: pulse-purple 2s infinite;
}

@-webkit-keyframes pulse-bluegrey {
  0% {
    stroke: #fff;
  }

  50% {
    stroke: #afe1fc;
  }

  100% {
    stroke: #fff;
  }
}

@keyframes pulse-bluegrey {
  0% {
    stroke: #fff;
  }

  50% {
    stroke: #afe1fc;
  }

  100% {
    stroke: #fff;
  }
}

.block-approach-wheel.current-group-3 .arrow svg {
  -webkit-animation: pulse-bluegrey 2s infinite;
  animation: pulse-bluegrey 2s infinite;
}

.block-approach-wheel .group-1 .navigation-title {
  color: #6be5b2;
}

.block-approach-wheel .group-1 svg path,
.block-approach-wheel .group-1 svg rect {
  stroke: #6be5b2;
}

.block-approach-wheel .group-2 .navigation-title {
  color: #bfa4f9;
}

.block-approach-wheel .group-2 svg path,
.block-approach-wheel .group-2 svg rect {
  stroke: #bfa4f9;
}

.block-approach-wheel .group-3 .navigation-title {
  color: #afe1fc;
}

.block-approach-wheel .group-3 svg path,
.block-approach-wheel .group-3 svg rect {
  stroke: #afe1fc;
}

.block-approach-wheel .heading {
  color: #fff;
  font-size: 30px;
}

@media only screen and (min-width: 320px) {
  .block-approach-wheel .heading {
    font-size: calc(30px + 38 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .block-approach-wheel .heading {
    font-size: 68px;
  }
}

.block-approach-wheel .navigation-title {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.875rem;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  min-height: 4.0625rem;
}

@media only screen and (min-width: 320px) {
  .block-approach-wheel .navigation-title {
    font-size: calc(16px + 10 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .block-approach-wheel .navigation-title {
    font-size: 26px;
  }
}

.block-approach-wheel__intro {
  height: auto;
  display: none;
  margin-bottom: 3%;
}

.block-approach-wheel__intro.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-approach-wheel__buttons {
  margin-top: 1.875rem;
}

@media (max-width: 992px) {
  .block-approach-wheel__wheel-right-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 33%;
  }
}

.block-approach-wheel [data-segment-content='1'] .btn {
  color: #1b232c;
  background-color: #6be5b2;
}

.block-approach-wheel [data-segment-content='1'] .btn:after {
  content: url('./assets/button-arrow.svg');
}

.block-approach-wheel [data-segment-content='2'] .btn {
  color: #1b232c;
  background-color: #bfa4f9;
}

.block-approach-wheel [data-segment-content='2'] .btn:after {
  content: url('./assets/button-arrow.svg');
}

.block-approach-wheel [data-segment-content='3'] .btn {
  color: #1b232c;
  background-color: #afe1fc;
}

.block-approach-wheel [data-segment-content='3'] .btn:after {
  content: url('./assets/button-arrow.svg');
}

.block-approach-wheel__pre-heading {
  color: #fff;
  font-size: 1.625rem;
  font-weight: 400;
}

.block-approach-wheel__wheel-row {
  position: relative;
}

.block-approach-wheel--half .block-approach-wheel__wheel-row {
  overflow-x: visible;
  overflow-y: clip;
  height: 31.25rem;
}

@media (min-width: 576px) {
  .block-approach-wheel--half .block-approach-wheel__wheel-row {
    height: 35rem;
  }
}

@media (min-width: 768px) {
  .block-approach-wheel--half .block-approach-wheel__wheel-row {
    height: 35rem;
  }
}

@media (min-width: 992px) {
  .block-approach-wheel--half .block-approach-wheel__wheel-row {
    height: 33.75rem;
  }
}

@media (min-width: 1300px) {
  .block-approach-wheel--half .block-approach-wheel__wheel-row {
    height: 36.25rem;
  }
}

.block-approach-wheel--half .block-approach-wheel__wheel-row:after {
  content: '';
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2.5rem;
  background: #1b232c;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#1b232c),
    to(rgba(27, 35, 44, 0))
  );
  background: linear-gradient(0deg, #1b232c 0%, rgba(27, 35, 44, 0) 100%);
}

@media (min-width: 992px) {
  .block-approach-wheel--half .block-approach-wheel__wheel-row:after {
    height: 5rem;
  }
}

.block-approach-wheel__content {
  font-size: 18px;
  color: #fff;
  opacity: 0.7;
}

@media only screen and (min-width: 320px) {
  .block-approach-wheel__content {
    font-size: calc(18px + 2 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .block-approach-wheel__content {
    font-size: 20px;
  }
}

.block-approach-wheel__content p {
  font-size: 18px;
  color: #fff;
  opacity: 0.7;
}

@media only screen and (min-width: 320px) {
  .block-approach-wheel__content p {
    font-size: calc(18px + 2 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .block-approach-wheel__content p {
    font-size: 20px;
  }
}

.block-approach-wheel__content-box-slide {
  display: none;
  padding: 2.5rem;
  overflow-y: scroll;
  height: 100%;
  max-height: 16.25rem;
  margin-right: 0.3125rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.block-approach-wheel__content-box-slide::-webkit-scrollbar {
  width: 0.3125rem;
}

.block-approach-wheel__content-box-slide::-webkit-scrollbar-track {
  padding: 0.625rem;
}

.block-approach-wheel__content-box-slide::-webkit-scrollbar-thumb {
  background-color: #5f656b;
  border-radius: 3.125rem;
  width: 0.3125rem;
}

.block-approach-wheel__content-box-slide.active {
  display: block;
}

.block-approach-wheel__content-box {
  border: 1px solid #8d9195;
  border-radius: 0.375rem;
  position: relative;
  margin-top: 6.25rem;
}

@media (min-width: 992px) {
  .block-approach-wheel__content-box {
    margin-top: 0;
  }
}

.block-approach-wheel__content-box:before {
  content: '';
  width: 60%;
  height: 37.5rem;
  border: 1px solid #8d9195;
  position: absolute;
  top: 0;
  left: -50%;
  border-top-left-radius: 4.125rem;
  border-right-color: transparent;
  border-bottom-color: transparent;
  display: none;
}

@media (min-width: 992px) {
  .block-approach-wheel__content-box:before {
    display: block;
  }
}

.block-approach-wheel__content-box:after {
  content: '';
  width: 0.0625rem;
  height: 100%;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #8d9195;
  display: block;
}

@media (min-width: 992px) {
  .block-approach-wheel__content-box:after {
    display: none;
  }
}

.block-approach-wheel__content-box-icon {
  position: absolute;
  top: -1.875rem;
  left: 50%;
  background-color: #5f656b;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (min-width: 992px) {
  .block-approach-wheel__content-box-icon {
    width: 5.5rem;
    height: 5.5rem;
    left: -2.75rem;
    top: -2.75rem;
    -webkit-transform: none;
    transform: none;
  }
}

.block-approach-wheel__content-box-icon svg {
  max-width: 50%;
}

.block-approach-wheel__content-box-heading {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}

@media only screen and (min-width: 320px) {
  .block-approach-wheel__content-box-heading {
    font-size: calc(20px + 2 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .block-approach-wheel__content-box-heading {
    font-size: 22px;
  }
}

.block-approach-wheel__content-box-copy {
  color: #fff;
  opacity: 0.7;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (min-width: 320px) {
  .block-approach-wheel__content-box-copy {
    font-size: calc(16px + 2 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .block-approach-wheel__content-box-copy {
    font-size: 18px;
  }
}

.block-approach-wheel__content-box-copy p {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

@media only screen and (min-width: 320px) {
  .block-approach-wheel__content-box-copy p {
    font-size: calc(18px + 2 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .block-approach-wheel__content-box-copy p {
    font-size: 20px;
  }
}

.block-approach-wheel .next,
.block-approach-wheel .prev {
  cursor: pointer;
  opacity: 0.5;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media (hover: hover) {
  .block-approach-wheel #js-next:hover .next,
  .block-approach-wheel #js-next:hover .prev,
  .block-approach-wheel #js-prev:hover .next,
  .block-approach-wheel #js-prev:hover .prev {
    opacity: 1;
  }
}

.block-approach-wheel .arrow {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 5rem;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

@media (min-width: 992px) {
  .block-approach-wheel .arrow {
    display: none;
  }
}

.block-approach-wheel.animating .arrow {
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(50px);
  transform: translateX(-50%) translateY(50px);
}

.block-approach-wheel .circle-carousel {
  position: relative;
  width: 48rem;
  height: 39.625rem;
  -webkit-transform: scale(0.4) translateX(-50%);
  transform: scale(0.3) translateX(-50%);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  display: inline-block;
  left: 50%;
  margin-bottom: -10rem;
  margin-top: 8.125rem;
}

@media (min-width: 400px) and (min-height: 750px) {
  .block-approach-wheel .circle-carousel {
    -webkit-transform: scale(0.4) translateX(-50%);
    transform: scale(0.4) translateX(-50%);
    /* margin-bottom: -15.75rem; */
    margin-top: 8.75rem;
  }
}

@media (min-width: 400px) and (min-height: 850px) {
  .block-approach-wheel .circle-carousel {
    -webkit-transform: scale(0.5) translateX(-50%);
    transform: scale(0.5) translateX(-50%);
    margin-bottom: -8.75rem;
    margin-top: 8.75rem;
  }
}

@media (min-width: 576px) and (min-height: 900px) {
  .block-approach-wheel .circle-carousel {
    -webkit-transform: scale(0.6) translateX(-50%);
    transform: scale(0.6) translateX(-50%);
    margin-bottom: -5.75rem;
    margin-top: 8.75rem;
  }
}

@media (min-width: 768px) and (min-height: 1000px) {
  .block-approach-wheel .circle-carousel {
    -webkit-transform: scale(0.6) translateX(-50%);
    transform: scale(0.6) translateX(-50%);
    margin-bottom: -3.75rem;
    margin-top: 10rem;
  }
}

@media (min-width: 992px) and (min-height: 1000px) {
  .block-approach-wheel .circle-carousel {
    -webkit-transform: scale(0.7) translateX(-50%);
    transform: scale(0.7) translateX(-50%);
    margin-bottom: 1.25rem;
  }
}

@media (min-width: 1300px) and (min-height: 1400px) {
  .block-approach-wheel .circle-carousel {
    -webkit-transform: scale(0.85) translateX(-50%);
    transform: scale(0.85) translateX(-50%);
    margin-bottom: 3.125rem;
  }
}

.block-approach-wheel .circle-carousel .slides {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
}

.block-approach-wheel .circle-carousel .slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 9;
  pointer-events: none;
  background-color: #1b232c;
  aspect-ratio: 1/1;
  border-radius: 50%;
  padding: 10%;
}

.block-approach-wheel .circle-carousel .slide:before {
  content: '';
  position: absolute;
  top: 4%;
  left: 4%;
  right: 4%;
  bottom: 4%;
  background-image: url('./assets/circle-inner.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.block-approach-wheel .circle-carousel .slide h2 {
  color: #fff;
  margin-bottom: 0;
  margin-top: -0.625rem;
  font-size: 3.75rem;
}

.block-approach-wheel .circle-carousel .slide.active {
  z-index: 1;
  opacity: 1;
}

.block-approach-wheel .circle-carousel .slide-copy {
  color: #fff;
  opacity: 0.7;
  font-size: 18px;
  text-align: center;
}

@media only screen and (min-width: 320px) {
  .block-approach-wheel .circle-carousel .slide-copy {
    font-size: calc(18px + 2 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .block-approach-wheel .circle-carousel .slide-copy {
    font-size: 20px;
  }
}

@keyframes rotate-wheel {
  0% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(0deg);
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-40deg);
    transform: translate3d(-50%, -50%, 0) rotate(-40deg);
  }
}

.block-approach-wheel .circle-carousel .pagination {
  position: absolute;
  top: 50%;
  left: 50%;
  /* -webkit-transform: translate3d(-50%, -50%, 0); */
  /* transform: translate3d(-50%, -50%, 0) rotate(0deg); */
  z-index: 1;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  animation: rotate-wheel 2s forwards;
}

.block-approach-wheel .circle-carousel .pagination:before {
  content: '';
  position: absolute;
  top: -35%;
  left: -35%;
  right: -35%;
  bottom: -35%;
  background-image: url('./assets/wheel-outline-02.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}

.block-approach-wheel .circle-carousel .pagination:after {
  content: '';
  position: absolute;
  top: -35%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -35%;
  aspect-ratio: 1/1;
  background-color: #1b232c;
  border-radius: 50%;
  z-index: -1;
}

.block-approach-wheel .circle-carousel .pagination .dot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 38%;
  height: 57%;
  border-radius: 1.875rem;
  pointer-events: auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #1b232c;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  line-height: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-clip-path: url('#mask');
  clip-path: url('#mask');
  text-decoration: none;
  opacity: 1;
}

/* @media (min-width: 992px) {
	.block-approach-wheel .circle-carousel .pagination .dot { */
/* opacity: 0.5 */
/* }
} */

.block-approach-wheel .circle-carousel .pagination .dot svg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 1.25rem;
}

@media (hover: hover) {
  .block-approach-wheel .circle-carousel .pagination .dot:hover {
    -webkit-transform: translate(-50%, -60%) scale(1.1);
    transform: translate(-50%, -60%) scale(1.1);
    cursor: pointer;
    opacity: 1;
  }

  .block-approach-wheel .circle-carousel .pagination .dot:hover svg {
    opacity: 1;
  }

  .block-approach-wheel .circle-carousel .pagination .dot:hover .dot-image {
    opacity: 1;
  }

  .block-approach-wheel .circle-carousel .pagination .dot:hover .dot-content {
    color: #fff;
  }
}

.block-approach-wheel .circle-carousel .pagination .dot-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-clip-path: url('#inner-mask');
  clip-path: url('#inner-mask');
  -webkit-transform: scale(0.92) translateY(-1.5%);
  transform: scale(0.92) translateY(-1.5%);
}

.block-approach-wheel .circle-carousel .pagination .dot-image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1b232c;
  opacity: 0.7;
  z-index: 9;
}

.block-approach-wheel .circle-carousel .pagination .dot-image:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.block-approach-wheel .circle-carousel .pagination .dot-content {
  position: relative;
  z-index: 9;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: -70%;
}

.block-approach-wheel .circle-carousel .pagination .dot-icon {
  display: block;
  margin: 0 auto 10px;
}

.block-approach-wheel .circle-carousel .pagination .dot-icon path {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.block-approach-wheel .circle-carousel .pagination .dot-pre-heading {
  font-size: 1.75em;
}

.block-approach-wheel .circle-carousel .pagination .dot-heading {
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 0.3125rem;
}

@media (min-width: 992px) {
  .block-approach-wheel .circle-carousel .pagination .dot-heading {
    font-size: 1.5rem;
  }
}

.block-approach-wheel .circle-carousel .pagination .item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.block-approach-wheel .circle-carousel .pagination .item.active-group .dot {
  opacity: 1;
}

.block-approach-wheel .circle-carousel .pagination .item.active .dot {
  opacity: 1;
  -webkit-transform: translate(-50%, -60%) scale(1.1);
  transform: translate(-50%, -60%) scale(1.1);
  color: #fff;
}

.block-approach-wheel .circle-carousel .pagination .item.active .dot-icon path {
  fill: #fff;
}

.block-approach-wheel .circle-carousel .pagination .item.active .dot-content {
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
}

.block-approach-wheel .circle-carousel .pagination .item.active .dot-image {
  opacity: 1;
}

/* .block-approach-wheel .circle-carousel .pagination .item:nth-child(-n+3) .dot { */
/* background-color: #6BE5B2; */
/* background: linear-gradient(to top, #6BE5B2 70%, darkgreen 71%);  */
/* background: radial-gradient(circle at bottom, #6BE5B2 70%, rgba(107, 229, 178, 0.5) 70%); */
/* } */

.block-approach-wheel
  .circle-carousel
  .pagination
  .item:nth-child(-n + 3)
  .dot-image:after {
  background: #6be5b2;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(107, 229, 178, 0)),
    to(#6be5b2)
  );
  background: linear-gradient(180deg, rgba(107, 229, 178, 0) 0%, #6be5b2 100%);
}

.block-approach-wheel
  .circle-carousel
  .pagination
  .item:nth-child(n + 4):nth-child(-n + 6)
  .dot {
  background-color: #bfa4f9;
}

.block-approach-wheel
  .circle-carousel
  .pagination
  .item:nth-child(n + 4):nth-child(-n + 6)
  .dot-image:after {
  background: #bfa4f9;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(191, 164, 249, 0)),
    to(#bfa4f9)
  );
  background: linear-gradient(180deg, rgba(191, 164, 249, 0) 0%, #bfa4f9 100%);
}

.block-approach-wheel
  .circle-carousel
  .pagination
  .item:nth-child(n + 7):nth-child(-n + 9)
  .dot {
  background-color: #afe1fc;
}

.block-approach-wheel
  .circle-carousel
  .pagination
  .item:nth-child(n + 7):nth-child(-n + 9)
  .dot-image:after {
  background: #afe1fc;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(175, 225, 252, 0)),
    to(#afe1fc)
  );
  background: linear-gradient(180deg, rgba(175, 225, 252, 0) 0%, #afe1fc 100%);
}

.block-approach-wheel .circle-carousel .next,
.block-approach-wheel .circle-carousel .prev {
  position: absolute;
  bottom: 6%;
  z-index: 1;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.block-approach-wheel .circle-carousel .next:before,
.block-approach-wheel .circle-carousel .prev:before {
  content: '';
  position: absolute;
  top: 50%;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-style: solid;
  border-color: transparent black;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (hover: hover) {
  .block-approach-wheel .circle-carousel .next:hover,
  .block-approach-wheel .circle-carousel .prev:hover {
    cursor: pointer;
    color: black;
  }

  .block-approach-wheel .circle-carousel .next:hover:before,
  .block-approach-wheel .circle-carousel .prev:hover:before {
    border-color: transparent white;
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }
}

.block-approach-wheel .circle-carousel .next {
  right: 5%;
}

.block-approach-wheel .circle-carousel .next:before {
  left: 65%;
  border-width: 30px 0 30px 70px;
}

.block-approach-wheel .circle-carousel .prev {
  left: 5%;
}

.block-approach-wheel .circle-carousel .prev:before {
  left: 35%;
  border-width: 30px 70px 30px 0;
}

/* single */
.behave-as-bg,
.behave-as-bg img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

article {
  padding-bottom: 2.5rem;
}

article a {
  color: #1b232c;
}

article a:hover {
  color: #6be5b2;
}

article img {
  border-radius: 0.375rem;
}

.post-hero {
  background: #cce9f8;
  border-bottom: 0.0625rem solid rgba(27, 35, 44, 0.3);
  padding: 5.3125rem 0 2.5rem;
  margin-bottom: 2.5rem;
}

.post-hero h1 {
  color: #1b232c;
  font-weight: 700;
  font-size: 38px;
  line-height: 110%;
  margin-bottom: 2.25rem;
}

@media only screen and (min-width: 320px) {
  .post-hero h1 {
    font-size: calc(38px + 24 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .post-hero h1 {
    font-size: 62px;
  }
}

.post-hero__avatar {
  width: 3.125rem;
  height: 3.125rem;
  margin-right: 0.625rem;
}

.post-hero__avatar img {
  border-radius: 3.125rem;
}

.post-hero__author-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-hero__author-wrapper {
  margin-bottom: 0.875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

.post-hero__author-details {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 150%;
}

.post-hero__author-details a {
  font-weight: 700;
  color: #1b232c !important;
  text-decoration: underline;
}

.post-hero__author {
  font-weight: 700;
}

.post-hero__date {
  margin-left: 1.1875rem;
}

.post-hero__meta ul {
  margin: 0;
  padding: 0;
}

.post-hero__meta ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 0 0 1.25rem;
  margin: 0;
  position: relative;
}

.post-hero__meta ul li:before {
  content: '|';
  position: absolute;
  left: 0.3125rem;
}

.post-hero__meta ul li:first-child {
  padding: 0;
}

.post-hero__meta ul li:first-child:before {
  content: '';
}

.post-details p.has-medium-font-size {
  font-size: 1.625rem !important;
}

.post-details p {
  font-size: 1.25rem;
}

.post-details ul {
  list-style: disc;
  margin-left: 1.25rem;
  font-size: 1.25rem;
  margin-bottom: 1.875rem;
}

.post-details ul li {
  margin-bottom: 0.625rem;
}

.post-sidebar {
  padding: 3rem 0 0;
  height: 100%;
}

@media (min-width: 992px) {
  .post-sidebar {
    padding: 5.8125rem 0 2.5rem;
  }
}

.post-sidebar-container {
  position: -webkit-sticky;
  position: sticky;
  top: 8.125rem;
}

.post-sidebar h2,
.post-sidebar h4 {
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 130%;
  border-bottom: 1px solid rgba(27, 35, 44, 0.3);
  padding-bottom: 0.625rem;
  margin-bottom: 1.25rem;
}

.post-sidebar__related {
  margin-bottom: 2.5rem;
}

.post-sidebar__related .cat {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.5rem;
  padding: 0.125rem 0.5rem !important;
  border: 0.0625rem solid #1b232c;
  border-radius: 0.25rem;
  margin: 0 0.625rem 0.625rem 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.post-sidebar__related .cat a {
  padding: 0 !important;
  border: 0;
}

.post-sidebar__related .cat:hover {
  background-color: #1b232c;
}

.post-sidebar__related .cat:hover a {
  color: #fff !important;
}

.post-sidebar__related a {
  text-decoration: none;
  color: #1b232c !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: transparent;
  border-bottom: 1px solid rgba(27, 35, 44, 0.3);
  display: block;
  padding: 0.625rem 0 1.25rem 0 !important;
}

.post-sidebar__related a:first-of-type {
  padding-top: 0 !important;
}

.post-sidebar__related a:last-of-type {
  border-bottom: none;
}

.post-sidebar__related a:hover {
  color: #fff;
}

.post-sidebar__related h5,
.post-sidebar__related h3 {
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 130%;
  color: #1b232c;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 0.5rem;
  padding-bottom: 0;
  padding-top: 0.8125rem;
}

.post-sidebar__related h5:hover,
.post-sidebar__related h3:hover {
  color: #6be5b2;
}

.post-sidebar__related p {
  padding: 0;
  margin: 0 0 0.3125rem;
  display: inline-block;
}

.post-sidebar__author {
  margin-bottom: 2.5rem;
}

@media (min-width: 992px) {
  .post-sidebar__author {
    margin-bottom: 5.75rem;
  }
}

.post-sidebar__author-details {
  background: #232836;
  border-radius: 0.5rem;
  padding: 2.5rem;
  color: #fff;
}

.post-sidebar__author-details a {
  color: #fff;
  text-decoration: none;
}

.post-sidebar__author-details a:hover {
  color: #6be5b2;
}

.post-sidebar__author-avatar {
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
}

.post-sidebar__author-avatar img {
  border-radius: 10rem;
}

.post-sidebar__author-title {
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 130%;
  margin-top: 1.375rem;
}

.post-sidebar__author-job {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  margin-bottom: 0.875rem;
}

.post-sidebar__author-bio {
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
}

.post-sidebar__author-bio p {
  font-size: 1rem;
}

.post-sidebar__author .more {
  color: #6be5b2;
  margin: 0.625rem 0;
  font-weight: 700;
  display: block;
}

.meta {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 2rem;
}

.meta .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
}

.meta .author-avatar {
  height: 100%;
}

.meta .author-avatar img {
  border-radius: 50%;
  margin-right: 1rem;
}

.meta .readtime {
  height: 40px;
  line-height: 40px;
  margin-right: 1rem;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.meta .readtime svg {
  width: 14px;
  height: 14px;
  margin-right: 0.5rem;
}

.meta .datetime {
  font-weight: normal;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 1rem;
}

.social-sharer {
  padding: 0;
  display: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.social-sharer.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: auto;
  bottom: -3.125rem;
  right: -1.25rem;
  padding: 0;
  -webkit-animation: pop 0.3s linear 1;
  animation: pop 0.3s linear 1;
}

@media (min-width: 992px) {
  .social-sharer.show {
    right: -5rem;
    top: -3.125rem;
    bottom: auto;
  }
}

.social-sharer__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-sharer__container p {
  font-weight: 600;
  margin-right: 1.5rem;
  margin-bottom: 0;
}

.social-sharer__container a {
  margin-right: 1rem;
  display: block;
}

.social-sharer__container a:last-of-type {
  margin-right: 0;
}

.social-sharer__container a svg {
  width: 24px;
  display: block;
}

.social-sharer__container a svg path {
  -webkit-transition: 0.3s fill ease-in-out;
  transition: 0.3s fill ease-in-out;
}

.social-sharer__container a svg:hover path {
  fill: #6be5b2;
}

.social-sharer__container .copy-link {
  position: relative;
}

.social-sharer__container .copy-link span.clipboard-notification {
  width: 100px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, calc(-100% - 12px), 0);
  transform: translate3d(-50%, calc(-100% - 12px), 0);
  font-size: 0.75rem;
  font-weight: bold;
  color: #1b232c;
  text-decoration: none;
  text-align: center;
  background-color: #1b232c;
  color: #fff;
  padding: 0.25rem;
  display: none;
}

.social-sharer__container .copy-link span.clipboard-notification:after {
  width: 0;
  height: 0;
  content: '';
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #1b232c transparent transparent transparent;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 99%, 0);
  transform: translate3d(-50%, 99%, 0);
}

@-webkit-keyframes pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.single-case-study article {
  position: relative;
  overflow: clip;
}

.single-case-study article:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(7%, #1b232c),
    color-stop(40%, #cce9f8),
    to(#cce9f8)
  );
  background: linear-gradient(180deg, #1b232c 7%, #cce9f8 40%, #cce9f8 100%);
  content: '';
  top: 0;
  position: absolute;
  width: 100%;
  height: 30%;
}

@media (min-width: 992px) {
  .single-case-study article:before {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(7%, #1b232c),
      color-stop(77%, #cce9f8),
      to(#cce9f8)
    );
    background: linear-gradient(180deg, #1b232c 7%, #cce9f8 77%, #cce9f8 100%);
  }
}

.single-case-study article .entry-content {
  position: relative;
  z-index: 1;
}

.single-case-study .block-home-hero {
  overflow: visible;
}

@media (min-width: 992px) {
  .single-case-study .block-home-hero {
    border-radius: 0 0.375rem 0.375rem 0.375rem;
  }
}

@media (min-width: 992px) {
  .single-case-study .block-home-hero.has-img::after {
    border-radius: 0 0.375rem 0.375rem 0.375rem;
  }
}

@media (min-width: 992px) {
  .single-case-study .block-home-hero__image img {
    border-radius: 0 0.375rem 0.375rem 0.375rem;
  }
}

.single-case-study .block-home-hero__circles {
  bottom: -6.25rem;
  background-image: url('./assets/circles.svg');
  opacity: 0.2;
}

.single-team__wrapper .heading {
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 320px) {
  .single-team__wrapper .heading {
    font-size: calc(38px + 24 * (100vw - 320px) / (1440 - 320));
  }
}

@media only screen and (min-width: 1440px) {
  .single-team__wrapper .heading {
    font-size: 62px;
  }
}

.single-team__wrapper p {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 150%;
}

.single-team__wrapper-title {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.single-team__wrapper-title p {
  font-size: 1.625rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
}

.single-team__wrapper-social {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.single-team__wrapper-social .linkedin {
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.625rem;
  margin-left: 2.5rem;
}

.single-team__image {
  position: relative;
  min-height: 31.25rem;
}
